export default function () {
  const router = useRouter();

  const route = useRoute();

  const recomputable = useRecomputable();

  const urlParams = recomputable<string[]>(() => {
    // use location.pathname instead of route.path to get updated params
    const path =
      (typeof window !== 'undefined' ? window : {})?.location?.pathname ||
      route.path;

    let params = router.resolve(path).params.params;

    if (params && !Array.isArray(params)) {
      params = [params];
    }

    if (!params || !Array.isArray(params) || !params.length) {
      return [];
    }

    return (params as string[]).filter(Boolean) || [];
  });

  router.afterEach(() => {
    urlParams.recompute();
  });

  return urlParams;
}
