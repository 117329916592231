import type { ComputedGetter } from 'vue';

export type RecomputableRef<T> = ComputedRef<T> & { recompute: () => void };

export default function () {
  return function recomputable<T>(cb: ComputedGetter<T>) {
    const reference = ref(0);
    const recomputable = computed(() => {
      // eslint-disable-next-line no-unused-expressions
      reference.value;
      return cb();
    }) as RecomputableRef<T>;
    recomputable.recompute = () => {
      reference.value++;
    };
    return recomputable;
  };
}
